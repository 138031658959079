.modal__popup {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    min-height: 40%;
    max-width: 350px;
    min-width: 300px;
    background-color: #373737;
	box-shadow: 0px 0px 11px 7px #000000;
	border-radius: 7px;

    padding: 30px 50px;

    transform: translate(-50%, -50%);
    display: none;

    z-index: 100;
}

.modal__popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
}

.modal__popup.modal_visible {
    display: block;
}

.modal__popup__overlay.modal_visible {
    display: block;
}

@media (max-width: 768px) {
    .modal__popup {
        width: 80%;
        height: fit-content;
        padding: 50px 10px;
    }
}

