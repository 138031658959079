.faq__container {
    padding-top: 50px;
    color: #fff;
    margin-bottom:10rem;
}

.faq__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title{
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#74d680;
}

.wrapper {
    display: flex;
    width: 80%;
    margin: 1rem auto;
    justify-content: center;
    align-items: center;
    gap: 5rem;
}

.accordion-faq{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 850px;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
}

.accordion__faq-heading h2{
    font-size: 20px;
    font-weight: 350;
}

.item {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 5px 30px;
    background-color: rgb(17, 20, 17);
    border-radius: 5px;
    box-shadow: 0px 0px 4px #216029;
    border: 1px solid #2a9f39;
    color:rgb(209, 200, 200);
    line-height: normal;
}
 
.accordion__faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0, 1, 0, 1);
    font-size: 16px;
    color:  rgb(209, 200, 200);
}
  
.content.show {
    height: auto;
    max-height: 999px;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.2s cubic-bezier(1, 0, 1, 0);
}