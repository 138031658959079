
.prizes__container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #74d680;
}

.prizes_text_container {
    font-size: 50px;
    padding-bottom: 70px;
}

.prize_box {
    width: 80%;
    display: flex;
    gap: 2rem;
    justify-content: space-evenly;
    align-items: center;
    min-height: 80vh;
}

.prizes {
    width: 25%;
    border: 3px solid #74d680;
    margin-bottom: 10px;
    position: relative;
    border-radius: 10px;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0px;
    display: flex;
    flex-direction: column; 
    order: 2;
}
.prize_1{
    transform: translateY(-50%) !important;
}


/*.prizes:hover {
    transform: scale(1.05);
}
*/

/* .prize_img {
    position: relative;
} */

.prizes img {
    width: 70%;
    aspect-ratio: initial;
    padding-bottom: 20px;
}


.prize-amount {
    font-weight: bold;
    color: #74d680;
    font-size: 40px;
}

@media (max-width: 1000px) {
    .prizes_text_container {
        padding-bottom: 0px;
    }
}

@media (max-width: 700px) {
    .prize_box {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .prize_1{
       transform: none !important; 
       order: 1;
    } 
    .prizes_text_container {
        padding-bottom: 20px;
    }
    .prizes{
        width: 80%;
        max-width: 250px;
    }
}
