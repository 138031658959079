@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&family=Lakki+Reddy&family=Montagu+Slab:opsz,wght@16..144,100;16..144,200;16..144,300;16..144,400;16..144,500;16..144,600;16..144,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track {
  display: none;
}

body::-webkit-scrollbar-thumb {
  background-color: #74d680;
  border-radius: 10px;
}

@font-face {
  font-family: "Bigdealer";
  src: url("assets/font/BigDealer.otf") format("opentype");
  font-display: swap;
}

:root {
  --iconic-text: "Josefin Sans", sans-serif;
  --primary-color: #378b29;
}

