.hints__container {
    box-sizing: border-box;
    width: 90%;
    max-width: 600px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.hints__header {
    box-sizing: border-box;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    box-shadow: 0px 10px 5px -12px rgba(0,0,0,0.7);
}

.hints__header__tab {
    flex-grow: 1;
    padding: 7px 0;
    text-align: center;
    background-color: #171717;
    cursor: pointer;
}
.hints__header__tab:hover {
    background-color: #464646;
}

.hints__header .hints__header__tab:not(:last-child) {
    border-right: 2px solid var(--primary-color);
}

.hints__header__tab.active {
    background-color: #333333;
}

.hints__body {
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    background-color: rgba(40, 170, 71, 0.46);
    color: #fff;
    min-height: 150px;
    text-align: center;
    padding: 30px ;

    display: flex;
    align-items: center;
    justify-content: center;
}