.submit__flag__container {
    width: 80%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    padding: 20px 0;
    padding-bottom: 150px;
}

.submit__flag__input {
    background-color: #171717;
    color: #fff;
    border: 2px solid #28aa47;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
}

.submit__flag__button {
    background-color: #28aa47;
    color: #000;
    border: 2px solid #28aa47;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
}

.submit__flag__button:hover {
    background-color: #000;
    color: #28aa47;
}

.submitted__flag__container {
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 20px 0;
    padding-bottom: 150px;

    text-align: center;
}

@media (max-width: 568px) {
    .submit__flag__container {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .submit__flag__input {
        box-sizing: border-box;
        width: 90%;
        margin-bottom: 20px;
    }

    .submit__flag__button {
        width: 90%
    }

    .submitted__flag__container {
        flex-direction: column;
        align-items: center;
    }
}