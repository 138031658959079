.points__summary {
    padding: 20px 30px;
    background-color: #37373799;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 1rem;
}

.points__summary:hover {
    cursor: pointer;
    background-color: #373737;
}

.points__modal__container {
    width: 100%;
    height: 100%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.points__modal__container__title {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #28AA47;
}

.points__modal__container__subtitle {
    margin: 0;
    margin-top: 7px;
}

.points__modal__container__subtitle > h4,h3 {
    margin: 0;
    padding: 0;
}

@media (max-width: 768px) {
    .points__modal__container__subtitle > h4,h3 {
        font-size: 1rem;
    }

    .points__modal__container__title {
        font-size: 1.5rem;
    }

    .points__modal__container__subtitle > ul  {
        font-size: 0.75rem;
    }
}