.error_wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    min-height: 100vh;

    padding: 2rem;
}

.error_oops {
    font-size: 4rem;
    font-weight: 700;
    color: #74d680;
}

.error_msg {
    font-size: 2rem;
    font-weight: 500;
}

@media (max-width: 800px) {
    .error_oops {
        font-size: 3rem;
    }

    .error_msg {
        font-size: 2rem;
    }
}

@media (max-width: 650px) {
    .error_oops {
        font-size: 2rem;
    }

    .error_msg {
        font-size: 1rem;
    }
}