.challenge__dynamic__instance__box {
	box-sizing: border-box;
	width: 80%;
	border: 2px solid #373737;
	padding: 10px 30px;
	border-radius: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;

	max-width: 600px;

	margin: 30px 0;

	text-align: center;
}

.challenge__dynamic__instance__box__header {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.challenge__dynamic__instance__box__power {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.challenge__dynamic__instance__box__icon_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	padding: 6px;
	cursor: pointer;
}

.challenge__dynamic__instance__box__icon_wrapper:hover {
	background-color: #373737;
}
.challenge__dynamic__instance__box__icon_wrapper.powerDisabled {
	cursor: not-allowed;
	background-color: none;
}

.challenge__dynamic__instance__box__power__button,
.challenge__dynamic__instance__box__power__status_container {
	width: 30%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	border: 2px solid #373737;
	border-radius: 20px;
	height: 40px;
	text-align: center;
}

.challenge__dynamic__instance__box__power__button {
	cursor: pointer;
}

.challenge__dynamic__instance__box__power__button:hover {
	background-color: #373737;
}
.challenge__dynamic__instance__box__power__button:active {
	background-color: #373737;
}

.challenge__dynamic__instance__box__power__button.powerDisabled {
	cursor: not-allowed;
	background-color: #3737376b;
}

.challenge__dynamic__instance__box__power__status_led {
	width: 10px;
	border-radius: 50%;
	aspect-ratio: 1/1;
}
@keyframes pulse {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.6;
	}
}

.challenge__dynamic__instance__box__access {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	font-size: 1.5rem;
	padding: 50px 0;
}

.challenge__dynamic__instance__box__access__status__text {
	font-size: 1rem;
}

.dynamic__heartbeat__modal {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40%;
	height: 40%;
	transform: translate(-50%, -50%);
	background-color: #373737;
	box-shadow: 0px 0px 11px 7px #000000;
	border-radius: 5px;
	min-width: 350px;
	z-index: 20;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;

	display: none;
}

.dynamic__heartbeat__modal__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000a3;
	z-index: 15;
	display: none;
}

.dynamic__heartbeat__modal.heartbeat__open {
	display: flex;
}

.dynamic__heartbeat__modal__overlay.heartbeat__open {
	display: block;
}

.challenge__dynamic__instance__box__heartbeat {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
}

.challenge__dynamic__instance__box__heartbeat__text {
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.challenge__dynamic__instance__box__heartbeat__button,
.dynamic__heartbeat__open__button {
	background-color: #28aa47;
	color: #000;
	border: 2px solid #28aa47;
	border-radius: 10px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 1rem;
}

.challenge__dynamic__instance__box__heartbeat__button:hover,
.dynamic__heartbeat__open__button:hover {
	background-color: #000;
	color: #28aa47;
}

.challenge__dynamic__instance__box__help__modal__content {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 2rem;
	text-align: left;
}

.instance__help__highlight {
	color: #28aa47;
}

@media (max-width: 800px) {
	.challenge__dynamic__instance__box__access {
		flex-direction: column;
		gap: 0;
	}
	.challenge__dynamic__instance__box__access__colon {
		display: none;
	}

	.challenge__dynamic__instance__box__power {
		font-size: 0.6rem;
	}
}
