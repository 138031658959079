.challenge__static__url__box {
	color: #bcbcbc;
    font-size: 1.75rem;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.challenge__static__url__link {
	text-decoration: none;
	cursor: pointer;
    color: #bcbcbc;
}

.challenge__static__url__link:hover {
	text-decoration: underline;
}

.challenge__static__url__link:active {
	color: var(--primary-color);
}

@media (max-width: 1000px) {
	.challenge__static__url__box {
		font-size: 1.5rem;
	}
}


@media (max-width: 600px) {
	.challenge__static__url__box {
		flex-direction: column;
	}
	 .challenge__static__url__box__colon {
		display: none;
	}
}