.challenge_wrapper {
	box-sizing: border-box;
	width: 100%;
	height: 100dvh;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	overflow: hidden;
}

.challenge__content__wrapper {
	box-sizing: border-box;
	flex-grow: 1;
	width: 100%;
	height: 100dvh;
	overflow-y: auto;
}

.challenge__content__wrapper > * {
	margin: auto;
}

.challenge__content__wrapper::-webkit-scrollbar {
	width: 0.7em;
}
.challenge__content__wrapper::-webkit-scrollbar-track {
	display: none;
}

.challenge__content__wrapper::-webkit-scrollbar-thumb {
	background-color: #74d680;
	border-radius: 10px;
}
