.hero_wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
}

.navbar__main_body {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem 1rem 2rem;
  align-items: center;
  /* border: 2px solid green; */
}
.and__txt {
  color: #378b29;
  font-size: 1.25rem;
  padding-left: 3rem;
  padding-right: 2rem;
}
.navbar__container {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar__main_body > a > img {
  width: 13rem;
}
.presents__txt {
  color: #ffff;
  font-size: 1.75rem;
  font-family: var(--iconic-text);
  margin-top: -80px;
  padding-bottom: 4rem;
}

.main__page_container {
  width: 100%;
  min-height: 90 vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.main__text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  margin-top: -100px;
  background-color: #378b29;
  background-image: linear-gradient(315deg, #378b29 0%, #74d680 74%);
  /* background: linear-gradient(90deg, #e10c5d 0%, #f9681c 100%); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.evnt__name_quest1 {
  font-size: 6rem;
  line-height: 1rem;
  font-family: "Bigdealer", "Poppins", "sans-serif";
  font-weight: 100;
}
.evnt__name_quest2 {
  font-size: 4rem;
  line-height: 1rem;
  padding-bottom: 1rem;
}

.reg__btn {
  margin-top: 3rem;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  background-color: var(--primary-color);
  border: none;
  font-size: 1.5rem;
  font-family: var(--iconic-text);
  border-radius: 10px;
  cursor: pointer;
}
.reg__btn:hover {
  color: #378b29;
  background-color: #000;
  border: 2px solid #378b29;
}

/* @media only screen and (max-width: 1300px) {
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 800px) {
} */

@media screen and (max-width: 680px) {
  .navbar__main_body {
    padding-top: 10%;
    gap: 0.5rem;
  }
  .excel_logo {
  }
  .and__txt {
    color: #378b29;
    font-size: 20px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .presents__txt {
    color: #ffff;
    font-size: 20px;
    font-family: var(--iconic-text);
    margin-top: -10%;
    padding-bottom: 5.5rem;
  }
  .navbar__main_body > a > img {
    width: 6.5rem;
  }
  .evnt__name_quest1 {
    font-size: 4rem;
    padding-bottom: 2rem;
  }
  .evnt__name_quest2 {
    font-size: 40px;
    padding-bottom: 2.5rem;
  }
  .main__text_container h2 {
    font-size: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .evnt__name_quest1 {
    font-size: 3rem;
    padding-bottom: 2rem;
  }
  .evnt__name_quest2 {
    font-size: 2rem;
    padding-bottom: 2.5rem;
  }
}

@media screen and (max-width: 380px) {
  .evnt__name_quest1 {
    font-size: 2.5rem;
    padding-bottom: 2rem;
  }
  .evnt__name_quest2 {
    font-size: 1.5rem;
    padding-bottom: 2.5rem;
  }
}

@media screen and (max-width: 380px) {
  .and__txt {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .reg__btn {
	padding: 1.25rem;
	font-size: 1.25rem;
  }
}

/* @media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
} */
