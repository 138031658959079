.winners__wrapper {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 100dvh;

    position: relative;;

    /* For Confetti */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
}

.winners__header {
	color: #28aa47;
    padding-bottom: 30px;
}

.winners__prize__box {
	width: 80%;
	display: flex;
	gap: 2rem;
	justify-content: space-evenly;
	align-items: center;
	min-height: 80vh;
}

.winners__prizes {
	width: 25%;
	border: 3px solid #74d680;
	margin-bottom: 10px;
	position: relative;
	border-radius: 10px;
	align-items: center;
	justify-content: space-evenly;
	padding: 20px 0px;
	display: flex;
	flex-direction: column;
	order: 2;
	animation: MoveUpDown 2s ease-in-out infinite;
}

.winners__prize__1 {
	transform: translateY(-50%) !important;
    animation: MoveUpDownDouble 2s ease-in-out infinite;
}

@keyframes MoveUpDownDouble {
	0%,
	100% {
		bottom: 0;
	}
	50% {
		bottom: 15px;
	}
}

@keyframes MoveUpDown {
	0%,
	100% {
		bottom: 0;
	}
	50% {
		bottom: 10px;
	}
}

.winners__prizes img {
	width: 40%;
	aspect-ratio: initial;
	padding-bottom: 20px;
}

.winners__name {
	font-weight: bold;
	color: #74d680;
	font-size: 23px;
}

@media (max-width: 800px) {
	.winners__prize__box {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.winners__prize__1 {
		transform: none !important;
		order: 1;
	}
	.winners__prizes {
		width: 80%;
		max-width: 250px;
	}
}
