.sidebar {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 27%;
	height: 100dvh;
	background-color: #152018;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 300px;
	min-width: 250px;
}

.sidebar__header {
	box-sizing: border-box;
	width: 100%;
	padding-top: 30px;
	text-align: center;
}

.sidebar__header__spacer {
	box-sizing: border-box;
	width: 100%;
	height: 40px;
}

.sidebar__user__spacer {
	box-sizing: border-box;
	width: 100%;
	flex-grow: 1;
}

.sidebar__link {
	box-sizing: border-box;
	width: 100%;
	display: block;
	text-align: center;
	padding: 10px 0;
	background-color: rgba(40, 170, 71, 0.2);
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 400;
	border: 1px solid #000;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.sidebar__link:hover {
	background-color: rgba(40, 170, 71, 0.4);
}

.sidebar__link.active {
	background-color: rgba(40, 170, 71, 0.6);
}

.sidebar__user__container {
	box-sizing: border-box;
	width: 100%;
	padding: 10px 5px;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.sidebar__user__logout {
	padding-right: 5px;
	cursor: pointer;
	width: 20px;
	aspect-ratio: initial;
}

.sidebar__btn {
	box-sizing: border-box;
	text-decoration: none;
	color: #000;
	background-color: rgba(40, 170, 71, 0.6);
	border: 2px solid rgba(40, 170, 71, 0.6);
	font-size: 1.5rem;
	border-radius: 10px;
	cursor: pointer;
	padding: 5px 10px;
	width: 70%;
	margin: auto;
}
.sidebar__btn:hover {
	color: rgba(40, 170, 71, 0.6);
	background-color: #000;
	border: 2px solid rgba(40, 170, 71, 0.6);
}

.sidebar__user__icon {
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
}

.sidebar__user__name {
	box-sizing: border-box;
	padding: 0 10px;
	text-align: center;
	color: #fff;
	font-size: 1rem;
	font-weight: 200;
	flex-grow: 1;
}

.sidebar__hamburger {
	box-sizing: border-box;
	width: 40px;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: rgba(40, 170, 71, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	top: 20px;
	left: 20px;
	display: none;
	position: absolute;
	z-index: 11;
}

.sidebar__hamburger:hover {
	background-color: rgba(40, 170, 71, 0.8);
}
.sidebar__overlay {
	display: none;
}

.sidebar__check {
	width: 20px;
	height: 20px;
	color: yellow;
}

.sidebar__check__empty {
	width: 20px;
	height: 20px;
	color: transparent;
}

.tooltip {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -60px;
}
.tooltip .tooltiptext::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

@media (max-width: 1000px) {
	.sidebar {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		z-index: 10;
	}

	.sidebar__hamburger {
		display: flex;
	}

	.sidebar.open {
		display: flex;
	}

	.sidebar__overlay.open {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100dvh;
		background-color: rgba(0, 0, 0, 0.6);
	}
}
