.challenge__view__page {
    box-sizing: border-box;
    width: 80%;
    min-height: 100dvh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.challenge__view__name {
    color: #28AA47;
    font-size: 3rem;
    text-align: center;
    padding: 0 1rem;
}

.challenge__view__description {
    color: #fff;
    text-align: center;
    width: 80%;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 300;
    font-family: 'Courier New', Courier, monospace;
}
.challenge__view__description__coming__soon {
    text-align: center;
}

@media (max-width: 800px) {
    .challenge__view__page {
        width: 100%;
    } 
}

@media (max-width: 660px) {


    .challenge__view__name {
        font-size: 2rem;
        margin-top: 5rem;
    }

    .challenge__view__description {
        text-align: center;
        font-size: 0.75rem;
    }
}