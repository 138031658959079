.leaderboard__wrapper {
	box-sizing: border-box;
	width: 100%;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
}

.leaderboard__header {
	font-size: 1.75rem;
	font-weight: 500;
	color: var(--primary-color);
}

.leaderboard__body {
	width: 80%;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	padding-bottom: 130px;
}

.leaderboard__user {
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 5rem;
	border-radius: 0.5rem;
	background-color: #37373799;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
}

.leaderboard__user__details {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 3rem;

	width: 60%;
}

.leaderboard__user__avatar {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	object-fit: cover;
	font-size: 0;
}

.leaderboard__user__name {
	text-align: left;
	flex-grow: 1;
	padding: 0 1rem;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.leaderboard__user:hover {
	background-color: #373737;
}

.leaderboard__user__points {
	text-align: center;
}

@media screen and (max-width: 768px) {
	.leaderboard__header {
        font-size: 1.25rem;
	}
	.leaderboard__user {
		padding: 1rem 2rem;
	}

	.leaderboard__user__avatar {
		width: 2rem;
		height: 2rem;
	}

	.leaderboard__user__details {
		gap: 0rem;
		width: 70%;
	}

	.leaderboard__user__points__header {
		width: 130px;
	}
}
