.register__page_container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.register__text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  background-color: #378b29;
  background-image: linear-gradient(315deg, #378b29 0%, #74d680 74%);
  /* background: linear-gradient(90deg, #e10c5d 0%, #f9681c 100%); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.evnt__name_quest1 {
  font-size: 6rem;
  line-height: 1rem;
}

.reg__btn_1 {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  font-size: 1.5rem;
  font-family: var(--iconic-text);
  border-radius: 10px;
  cursor: pointer;
}
.reg__btn_1:hover {
  color: #378b29;
  background-color: #000;
  border: 2px solid #378b29;
}
.disabled {
  color: #378b29;
  background-color: #000;
  border: 2px solid #378b29;
  cursor: not-allowed;
}

.countdown {
  color: #378b29;
  padding: 2rem 0;
  font-size: 2rem;
}

.reg_annottation {
  color: #fff;
  font-size: 1rem;
  padding: 1.5rem;
}
@media screen and (max-width: 680px) {
  .evnt__name_quest1 {
    font-size: 4rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .evnt__name_quest1 {
    font-size: 3rem;
    padding-bottom: 2rem;
  }
  .evnt__name_quest2 {
    font-size: 2rem;
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 380px) {
  .evnt__name_quest1 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  .evnt__name_quest2 {
    font-size: 1rem;
    padding-bottom: 2.5rem;
  }
}
