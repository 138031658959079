.instructions__wrapper {
    box-sizing: border-box;
    width: 80%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.instructions__header {
    font-size: 1.75rem;
	font-weight: 500;
    color: #28aa47;
}

.instruction__highlight {
    color: #28aa47;
}

.instructions__body {
    text-align: justify;
    font-size: 1.1rem;
    width: 80%;
}

@media (max-width: 768px) {
    .instructions__wrapper {
        width: 100%;
    }

    .instructions__header {
        font-size: 1.25rem;
    }

    .instructions__body {
        text-align: center;
    }
}